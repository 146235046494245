import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Layout from "../../components/common/layout/layout"
import SEO from "../../components/common/layout/seo"
import Navigation from "../../components/common/navigation/navigation"
import Footer from "../../components/sections/footer"
import Img from 'gatsby-image'

import form from "../../static/merchant-application.pdf"
import terms from "../../static/merchant-terms.pdf"

const DownloadForm = () => (
  <Layout>
    <SEO title="Merchant Application | Dili - Zero Cost EMI Option for Professional Skilling" />
    <Navigation></Navigation>
    <CategoryContainer>
        <CategoryHeader>Download our merchant application form</CategoryHeader>
        <CategoryText>To fill and submit this form, complete the following steps:
        <br />
        <br />
        <CText>
        <ol>
            <li>Download the application as a PDF file using <CLink><a href={form}>this link</a></CLink>.<br/><br/></li>
            <li>Review our merchant terms and conditions <CLink><a href={terms}>here</a></CLink>.<br/><br/></li>
            <li>Complete the form, either by printing it and scanning the filled form or by annotating the PDF.<br/><br/></li>
            <li>Review our <CLink to="/apply/documents">document requirements</CLink>.<br/><br/></li>
            <li>Upload the completed application and required documents to <CLink to="https://forms.gle/r7qCCrhNn3DYb7Jc7">this form</CLink>.<br/><br/></li>
            <li>Await a reply from us. We will review your application and reach out to you.<br/><br/></li>
        </ol>
        </CText>
        </CategoryText>
    <Spacing />
    </CategoryContainer>
    <Footer />
  </Layout>
)

export default DownloadForm


export const Spacing = styled.div`
    margin-bottom: 120px;
`

export const CategoryContainer = styled.div`
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 160px;
`

export const CategoryHeader = styled.div`
    color: #142630;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: -0.02em;
    margin-right: 40px;
`

export const CategoryText = styled.div`
    color: #465F6D;
    font-size: 20px;
    letter-spacing: -0.02em;
    line-height: 1.5;
    margin-top: 20px;
    width: 70%;
`

export const Icon = styled.img`
    width: 80px;
    height: 80px;
    margin-bottom: 32px;
`

export const Stores = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

export const Store = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 32px;
    margin-bottom: 80px;
`

export const StoreImg = styled(Img)`
    margin-bottom: 16px;
    border-radius: 20px;
`

export const StoreHeader = styled.div`
    font-size: 24px;
    margin-bottom: 8px;
    font-weight: bold;
    width: 320px;
`

export const StoreText = styled.div`
    font-size: 18px;
    color: grey;
    margin-bottom: 16px;
    width: 320px;
    line-height: 1.3;
`

export const StoreButton = styled.button`
    background: black;
    color: white;
    font-weight: bold;
    font-size: 14px;
    width: 96px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 40px;
`

export const StoreC = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

export const CText = styled.div`
    line-height: 1.5;
`

export const CLink = styled(Link)`
    color: #0084DD;
`

export const sli = styled.li`
    margin-bottom: 20px;
`
